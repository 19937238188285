<template>
  <q-dialog
    v-if="confirm"
    v-model="confirm"
    persistent
    full-width
    full-height
    transition-show="slide-up"
    transition-hide="slide-down"
  >
    <q-card class="bg-grey-11 default-rounded overflow-hidden">
      <q-card-section class="row items-center justify-center">
        <q-avatar
          :size="$q.platform.is.mobile ? '2.5 em' : '4em'"
          icon="campaign"
          color="light-blue-10"
          text-color="white"
        />
        <span
          class="q-ml-sm text-weight-medium text-light-blue-10 text-center dialog-title"
          v-html="$t('global.terms_to_sign')"
        ></span>
      </q-card-section>
      <q-card-section>
        <q-tabs
          v-model="tab"
          :vertical="$q.platform.is.mobile"
          dense
          class="bg-grey-3 text-grey-7 wrap"
          active-color="primary"
          align="justify"
        >
          <q-tab
            v-for="term in terms"
            :key="`tab-${term.id}`"
            :name="term.id"
            :label="term.description"
          />
        </q-tabs>
        <q-tab-panels v-model="tab" animated class="text-grey-9">
          <q-tab-panel
            v-for="term in terms"
            :key="`tab-panel-${term.id}`"
            :name="term.id"
          >
            <div class="row justify-center">
              <div class="terms-content scroll">
                <quill-editor
                  :value="term.doc"
                  :disabled="state.disabled"
                  :options="state.editorOption"
                />
              </div>
            </div>
          </q-tab-panel>
        </q-tab-panels>
      </q-card-section>
      <q-card-section class="row footer-content">
        <q-item tag="label">
          <q-item-section avatar top>
            <q-checkbox keep-color v-model="agreeTerms" color="grey-7" />
          </q-item-section>
          <q-item-section>
            <q-item-label v-html="$t('global.terms_new_accepted')" />
          </q-item-section>
        </q-item>
        <q-card-actions align="left">
          <q-btn
            flat
            :disable="!agreeTerms"
            @click="pushTerms"
            :label="$t('global.continue')"
            color="text-light-blue-10"
            v-close-popup
          />
          <q-btn
            flat
            @click="signOut"
            :label="$t('global.recuse')"
            color="text-negative"
            class="text-negative"
            v-close-popup
          />
        </q-card-actions>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import { notifyError, notifySuccess } from "@/shared/helpers/notify";
import { createNamespacedHelpers } from "vuex-composition-helpers";
import quillEditor from "@/modules/main/components/quillEditor";
import { reactive, ref, onMounted } from "vue";

const { useActions } = createNamespacedHelpers("auth");

export default {
  name: "userTermsCheck",

  components: { quillEditor },

  setup() {
    const agreeTerms = ref(false),
      tab = ref(null),
      terms = ref([]),
      confirm = ref(false),
      state = reactive({
        editorOption: {
          readOnly: true,
          theme: "snow",
          modules: {
            toolbar: false,
          },
        },
        disabled: true,
      });

    const { fetchUserTerms, acceptUserTerms, signOutAction } = useActions([
      "fetchUserTerms",
      "acceptUserTerms",
      "signOutAction",
    ]);

    onMounted(() => {
      fetchUserTerms().then(({ data }) => {
        terms.value = data || [];
        if (terms.value && terms.value[0]) {
          tab.value = terms.value[0].id;
        }
        confirm.value = terms.value.length > 0;
      });
    });

    const pushTerms = () => {
        acceptUserTerms()
          .then(() => {
            notifySuccess("notify.terms_refreshed_as_success");
          })
          .catch(() => {
            notifyError("notify.terms_unprocessed");
          });
      },
      signOut = () => {
        signOutAction().finally(() => location.reload());
      };

    return {
      tab,
      state,
      terms,
      confirm,
      agreeTerms,
      signOut,
      pushTerms,
    };
  },
};
</script>

<style lang="scss" scoped>
.footer-content {
  position: absolute;
  bottom: 0;
  width: 100%;
  justify-content: center;
  margin: 0;
  padding: 0;
  background: #f5f5f5;
  .text-sign-up-footer {
    a {
      text-decoration: none;
      color: #222222;
      font-weight: 600;
    }
  }
}
.terms-content {
  height: 60vh;
  width: 100%;
  max-height: 60vh;
  max-width: 900px;
}
.dialog-title {
  font-size: 1.3em;
}
.mobile {
  .dialog-title {
    font-size: 1em;
  }
  .terms-content {
    max-height: 50vh;
    max-width: 900px;
  }
}
</style>
