<template>
  <q-item v-ripple active-class="menu-item-active">
    <q-tooltip
      class="desktop-only"
      v-if="miniState"
      anchor="center right"
      :offset="miniState ? [5, 10] : null"
      self="center left"
    >
      <span class="font-size-10 text-white">
        <q-skeleton type="text" />
      </span>
    </q-tooltip>
    <q-item-section avatar>
      <div>
        <q-skeleton type="QAvatar" size="20px" />
      </div>
    </q-item-section>
    <q-item-section>
      <q-skeleton type="text" />
    </q-item-section>
  </q-item>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "menu-item-skeleton",

  props: {
    miniState: {
      type: Boolean,
    },
  },
});
</script>

<style scoped lang="scss">
.font-size-10 {
  font-size: 11px;
}
</style>
