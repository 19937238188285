<template>
  <q-btn round dense flat :ripple="false">
    <div class="text-weight-medium text-capitalize q-ml-sm q-mr-sm">
      <q-icon name="diversity_3" class="q-mr-sm" />
      <template v-if="$q.screen.width > 900">
        {{ $t("Grupos") }}
        <q-icon name="expand_more" class="q-ml-sm" />
      </template>
    </div>
    <q-menu
      class="q-mt-lg full-width"
      transition-show="jump-down"
      transition-hide="jump-up"
      min-width="260px"
      max-width="320px"
      anchor="bottom middle"
      self="top middle"
    >
      <q-list bordered separator v-if="groups.length > 0">
        <div class="bg-grey-4 q-pa-md text-justify">
          Acesse e faça parte dos nossos grupos para receber atualizações!
        </div>

        <template v-for="(el, i) in formatNetworks(groups)" :key="i">
          <q-item clickable v-ripple :active="active" @click="handleAction(el)">
            <q-item-section avatar>
              <q-icon :name="el.icon" />
            </q-item-section>
            <q-item-section class="text-uppercase">{{
              el.message
            }}</q-item-section>
          </q-item>
        </template>
      </q-list>
      <div class="text-center bg-grey-4 q-pa-xl q-ma-md default-rounded" v-else>
        Não há grupos disponíveis.
      </div>
    </q-menu>
  </q-btn>
</template>

<script setup>
import { useGetters } from "vuex-composition-helpers";

const { groups } = useGetters({
  groups: "views/getCardLink",
});

const formatNetworks = (groups) => {
  let formatedNetworks = [];

  groups.forEach((group) => {
    formatedNetworks.push({
      icon: `fab fa-${group.icon.replace("_group", "")}`,
      link: group.value,
      message: `Grupo do ${group.icon.replace("_group", "")}`,
    });
  });

  const result = formatedNetworks.filter((e) => e.link);
  return result;
};

const handleAction = (group) => {
  if (group.link) window.open(group.link, "_blank");
};
</script>
