<template>
  <q-item
    clickable
    v-ripple
    :to="{ name: route.name }"
    :style="`color: ${itemTextColor}`"
    :active-class="isLogout ? '' : 'active-class'"
  >
    <q-tooltip
      class="desktop-only"
      v-if="miniState"
      anchor="center right"
      :offset="miniState ? [5, 10] : null"
      self="center left"
    >
      <span class="font-size-10">{{ $t(route.meta.label) }}</span>
    </q-tooltip>
    <q-item-section avatar>
      <div v-if="route.meta.isNew">
        <q-btn dense round flat :icon="route.meta.icon">
          <q-badge floating color="blue" rounded />
        </q-btn>
      </div>
      <div v-else>
        <q-btn dense round flat :icon="route.meta.icon" />
      </div>
    </q-item-section>
    <q-item-section>
      {{ $t(route.meta.label) }}
    </q-item-section>
  </q-item>
</template>

<script>
export default {
  name: "menu-item",

  props: {
    miniState: {
      type: Boolean,
    },
    route: {
      type: Object,
    },
    isLogout: {
      type: Boolean,
      default: false,
    },
    itemTextColor: {
      type: String,
      default: "#FFFFFF",
    },
  },
};
</script>

<style scoped lang="scss">
.font-size-10 {
  font-size: 11px;
}
.active-class {
  border-left: 3px solid #ffffff;
}
</style>
