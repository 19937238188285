<template>
  <div :class="{ mini: miniState }" class="absolute-top user-store-content">
    <div class="row no-wrap absolute-center q-pa-sm items-center full-width">
      <router-link :to="{ name: 'account.personal-info' }">
        <avatar :size="miniState ? '42px' : '70px'" />
      </router-link>
      <div class="description q-ml-sm">
        <div class="column text-center">
          <span class="text-white text-weight-bold text-uppercase"
            >{{ $t("global.welcome") }},
          </span>
          <span class="text-grey-custom">{{ first_name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { createNamespacedHelpers } from "vuex-composition-helpers";
import Avatar from "@/modules/main/components/avatar";

const partnerNamespace = createNamespacedHelpers("partner"),
  authNamespace = createNamespacedHelpers("auth");

export default {
  name: "user-info",

  components: { Avatar },

  props: {
    miniState: {
      type: Boolean,
    },
  },

  setup() {
    const { partner } = partnerNamespace.useGetters({
      partner: "getPartner",
    });

    const { user } = authNamespace.useState({
        user: "user",
      }),
      { first_name } = authNamespace.useGetters({
        first_name: "getFirstname",
      });

    return {
      first_name,
      partner,
      user,
    };
  },
};
</script>

<style lang="scss" scoped>
.user-store-content {
  height: 100px;
  overflow: hidden;
  .text-grey-custom {
    color: #ffffff;
  }
  .description {
    font-size: 12px;
  }
  &.min {
    .description {
      display: none;
    }
  }
}
</style>
