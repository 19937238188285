import script from "./card-stores.vue?vue&type=script&setup=true&lang=js"
export * from "./card-stores.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QSpinnerGears from 'quasar/src/components/spinner/QSpinnerGears.js';
import QLinearProgress from 'quasar/src/components/linear-progress/QLinearProgress.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn,QIcon,QMenu,QTabs,QTab,QSpinnerGears,QLinearProgress,QTabPanels,QTabPanel});
