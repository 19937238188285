<template>
  <q-dialog
    transition-show="slide-up"
    transition-hide="slide-down"
    seamless
    position="bottom"
  >
    <q-card style="width: 754px; max-width: 95vw; height: 550px">
      <div
        class="q-mx-md q-mt-md q-mb-xs text-center text-grey-8 row items-center self-center"
      >
        <q-space />
        <q-icon class="q-ma-xs q-ml-lg" name="forum"></q-icon>
        <span class="text-uppercase title">{{ customerService.title }}</span>
        <q-space />
        <q-btn icon="close" flat round dense v-close-popup />
      </div>
      <div class="text-grey-8 text-center">
        {{ customerService.description }}
      </div>
      <div
        class="row justify-center q-mt-md q-mb-sm text-justify card-text q-pa-md"
      >
        <q-card
          v-for="(item, i) in getValues(
            JSON.parse(customerService.value).types
          )"
          :key="i"
          style="cursor: pointer"
          class="col-12 col-md-5 q-pt-lg q-pb-lg q-pr-sm q-pl-sm q-ma-md bg-grey-4 card_conteiner"
          @click="getContacts(item.icon, item.value)"
          clicklable
        >
          <q-card-section>
            <q-img
              :src="getUrl(item)"
              width="30px"
              height="30px"
              class="q-mr-md"
            ></q-img
            ><span class="text-uppercase">
              {{ getKeys(JSON.parse(customerService.value).types, i) }}</span
            >
            <p class="q-mt-md">{{ item.subtext }}</p>
          </q-card-section>
        </q-card>
      </div>
      <div class="text-center q-ma-sm">
        <span class="text-grey-8 text-caption">
          {{ customerService.footer }}</span
        >
      </div>
    </q-card>
  </q-dialog>
</template>

<script>
import {
  customerServiceChat,
  customerServiceEmail,
  customerServiceWhatsapp,
  customerServiceInstagram,
} from "@/shared/helpers/networks";
import { defineComponent } from "vue";

export default defineComponent({
  name: "contact-component",

  props: {
    customerService: {
      required: true,
    },
  },

  setup() {
    const getValues = (customerServiceObject) => {
        return Object.values(customerServiceObject);
      },
      getKeys = (customerServiceObject, index) => {
        return Object.keys(customerServiceObject)[index];
      },
      getUrl = (customerServiceObject) => {
        return `/` + customerServiceObject.icon + `.png`;
      },
      getContacts = (item, value) => {
        switch (item) {
          case "envelope":
            customerServiceEmail(value);
            break;
          case "whatsapp":
            customerServiceWhatsapp(value);
            break;
          case "instagram":
            customerServiceInstagram(value);
            break;
          case "chat":
            customerServiceChat(false);
            break;
        }
      };

    return { getValues, getKeys, getUrl, getContacts };
  },
});
</script>

<style lang="scss" scoped>
$color: rgb(236, 236, 236);
.title {
  font-size: 18px;
  font-weight: bold;
}
.text-card {
  box-shadow: none;
}
.card_conteiner:hover {
  background-color: $color !important;
}
</style>
