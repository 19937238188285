import { baseURL } from "../../shared/http/api"
import Cookies from 'quasar/src/plugins/cookies/Cookies.js';

export const checkPartnerSlugCanInstallPwa = async () => {
    const partnerSlug = Cookies.get('slug');
    const endpoint = `${baseURL}/api/v1/parameters/p/${partnerSlug}/install_pwa`;

    const request = await fetch(endpoint, {
        method: 'GET'
    });

    if ( !request.ok ) {
        throw new Error(`Houve um erro ao buscar status 'install_pwa'. STATUS: ${request.status}`)
    }

    const response = await request.json();

    return response
}