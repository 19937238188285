<template>
  <q-list padding class="fat" style="z-index: 999999999999">
    <template v-if="hasRoutes">
      <template v-for="(route, key) in filterRoutes(routes)" :key="key">
        <menu-item
          :itemTextColor="listColor"
          :route="route"
          :miniState="miniState"
          v-if="route.name != 'forum' || route.name != 'exit'"
        />
      </template>
      <template v-for="(route, key) in routes" :key="key">
        <menu-item
          :itemTextColor="listColor"
          @click="showModalFunc()"
          :route="route"
          :miniState="miniState"
          v-if="route.name == 'forum' && showItem"
        />
      </template>

      <menu-item
        :itemTextColor="listColor"
        :isLogout="true"
        @click="signOut()"
        :route="{
          meta: {
            sort: 12,
            isRouteExit: true,
            visible: true,
            label: 'dashboard.card_user.exit',
            icon: 'logout',
          },
        }"
        :miniState="miniState"
      />
    </template>
    <template v-else>
      <menu-item-skeleton
        v-for="(e, i) in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
        :key="i"
      />
    </template>
  </q-list>

  <contact-component
    v-model="showModal"
    @closeThisModal="showModalFunc()"
    :customerService="getCustomerService"
  />
</template>

<script>
import { useActions, useGetters } from "vuex-composition-helpers";
import MenuItemSkeleton from "./skeleton/menu-item-skeleton.vue";
import ContactComponent from "./contact-component.vue";
import MenuItem from "./menu-item.vue";
import { useRouter } from "vue-router";
import { sortBy } from "lodash";

import { ref, onMounted, defineComponent } from "vue";

export default defineComponent({
  name: "menu-list",

  props: {
    miniState: {
      type: Boolean,
    },
    listColor: {
      type: String,
      default: "#FFFFFF",
    },
  },

  components: { ContactComponent, MenuItem, MenuItemSkeleton },

  setup() {
    const menuRoutes = ref([]),
      hasRoutes = ref(false);

    const showModal = ref(false),
      showItem = ref(false);

    const {
        signOutAction,
        fetchCustomerServiceAction,

        fetchAlcadas,
      } = useActions({
        signOutAction: "auth/signOutAction",
        fetchCustomerServiceAction: "partner/fetchCustomerServiceAction",
        fetchAlcadas: "views/fetchAlcadas",
      }),
      { getCustomerService, getCoursesTotal, getMenuParam, partner, menus } =
        useGetters({
          getCustomerService: "partner/getCustomerService",
          getCoursesTotal: "courses/getCoursesTotal",
          getMenuParam: "views/getMenuParam",
          partner: "partner/getPartner",
          menus: "views/getAlcadas",
        });

    let routes = sortBy(
      useRouter()
        .getRoutes()
        .filter((r) => r.meta.visible),
      (r) => r.meta.sort,
      "asc"
    );

    const filterRoutes = (routes) =>
      routes.filter((r) => menus.value.includes(r.name));

    onMounted(() => {
      fetchAlcadas().then(() => (hasRoutes.value = true));

      fetchCustomerServiceAction().then(() => {
        if (Object.keys(getCustomerService.value).length) showItem.value = true;
      });
    });

    return {
      menus,
      partner,
      routes,
      menuRoutes,
      hasRoutes,
      showItem,
      showModal,
      getMenuParam,
      getCoursesTotal,
      getCustomerService,
      filterRoutes,
      showModalFunc: () => (showModal.value = !showModal.value),
      signOut: () =>
        signOutAction().finally(() => {
          location.href = `/${partner.value.slug}/login`;
        }),
    };
  },
});
</script>
