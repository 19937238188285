<template>
  <q-btn round dense flat icon="notifications">
    <q-badge v-show="notifications.length > 0" floating>
      {{ notifications.length }}
    </q-badge>
    <q-menu class="q-mt-lg" :offset="[10, 10]">
      <q-list class="card-max-width">
        <messages
          :isMenu="true"
          :show-skeleton="inRequest"
          :show-icon="false"
          item-class="q-pa-md"
          :messages="notifications"
        />
        <q-card class="text-center no-shadow no-border">
          <q-btn
            color="black"
            class="full-width"
            :to="{ name: 'notifications' }"
            :label="$t('notifications.show_all')"
          />
        </q-card>
      </q-list>
    </q-menu>
  </q-btn>
</template>

<script>
import Messages from "@/modules/main/pages/notifications/components/messages";
import { createNamespacedHelpers } from "vuex-composition-helpers";

const { useGetters } = createNamespacedHelpers("notifications");

export default {
  name: "card-notifications",

  components: { Messages },

  setup() {
    const { inRequest, notifications } = useGetters({
      inRequest: "getNotificationsOnRequest",
      notifications: "getUnreadNotifications",
    });

    return {
      inRequest,
      notifications,
    };
  },
};
</script>

<style lang="scss" scoped>
.card-max-width {
  max-width: 500px;
}
</style>
