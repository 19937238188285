<template>
  <q-btn round dense flat icon="military_tech">
    <q-menu class="q-mt-lg" :offset="[10, 10]">
      <q-list style="min-width: 100px"> </q-list>
    </q-menu>
  </q-btn>
</template>

<script>
export default {
  name: "card-cluster",
};
</script>
